import React, { useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import SubscribeForm from '../components/SubscribeForm';
import TrustpilotBlock from '../components/TrustpilotBlock';
import ImageVacation from '../components/Image/ImageVacation';
import TrustpilotHorizontal from '../components/TrustpilotHorizontal';
import SearchForm from '../components/FindFlightsBlock/SearchForm/SearchForm';

import { useWindowSize } from '../helpers/useWindowSize';
import {
  Text,
  ImageContent,
  Container
} from '../components/ContentVacation/Components/ComponentsContext';
import VacationRecentArticles from '../components/VacationRecentArticles';

import '../components/ContentVacation/ContentVacation.css';
import { baseIBEURL } from '../configs';

const VacationTemplate = ({ phoneNumber, ...props }) => {
  const [phoneNum, setTest] = useState({ phoneNumber });
  const [width] = useWindowSize();
  const {
    slug,
    title,
    subTitle,
    titleBackgroundImage,
    overviewPlan,
    overviewImages,
    overview,
    days,
    mustSee,
    memoryMakers,
    inspirationTags,
    weatherClimate
  } = props.data.contentVacationJson;
  const seotitle = `${title} | Travelopod`;
  const seoDescription =
    'Find and book the cheapest flights to any destination at Travelopod! Save money on unique deals for flights, hotels and car rentals online or call us 24/7 for live booking assistance.';

  return (
    <Layout
      isStaticPage
      passPhoneNumber={phoneNum => {
        setTest({ phoneNumber: phoneNum });
      }}
    >
      <SEO title={seotitle} description={seoDescription} />
      <div className="block-title">
        <ImageVacation alt="blogVacation" filename={titleBackgroundImage} />
        <h1 className="title-text">{title}</h1>
      </div>
      <div className="main c-content-page">
        <div className="page-content">
          <div className="content-blog">
            <Text type="h2" pb="24" textAlign="center">
              {title}
            </Text>
            <Text type="h2" pb="24" textAlign="center">
              {subTitle}
            </Text>
            <Text type="h5">Overview:</Text>
            <Text type="p">{overview}</Text>
            {overviewPlan.map((item, i) => (
              <Text type="p" key={i.toString()}>
                {item}
              </Text>
            ))}
            <div className="block-images">
              {overviewImages.map((image, i) => (
                <ImageContent image={image} key={i.toString()} />
              ))}
            </div>
            <Text type="h5" pt="20" textDecoration="underline">
              The Plan:
            </Text>
            {days.map((day, i) => (
              <React.Fragment key={i.toString()}>
                <Text
                  type="p"
                  fontWeight="bold"
                  pt={i > 0 ? '50' : '10'}
                  key={i.toString()}
                >
                  Day {i + 1} {day.description}
                </Text>
                <ImageContent
                  image={day.image}
                  display="inline-block"
                  width="40%"
                />
                <Text
                  type="p"
                  display="inline-block"
                  width="60%"
                  pl="10"
                  pt="0"
                  pb="0"
                  verticalAlign="top"
                >
                  {day.text}
                </Text>
              </React.Fragment>
            ))}
            <Text type="h5" textDecoration="underline">
              Must See:
            </Text>
            {mustSee.map((item, i) => (
              <React.Fragment key={i.toString()}>
                <Text type="p" fontWeight="bold" textDecoration="underline">
                  {item.title}:
                </Text>
                <Text type="p" pt="0">
                  {item.description}
                </Text>
              </React.Fragment>
            ))}

            <Text type="h5" fontWeight="bold" textDecoration="underline">
              Memory Makers:
            </Text>
            {memoryMakers.map(({ title, places }, i) => (
              <React.Fragment key={i.toString()}>
                <Text type="p" fontWeight="bold">
                  {title}
                </Text>
                {places.map((place, i) => (
                  <React.Fragment key={i.toString()}>
                    <ImageContent
                      mt="0"
                      mb="20"
                      image={place.image}
                      display="inline-block"
                      width="40%"
                    />
                    <Container
                      styles={{
                        display: 'inline-block',
                        width: '60%',
                        'vertical-align': 'top',
                        'padding-left': '10px'
                      }}
                    >
                      <Text type="p" fontWeight="bold" pt="0">
                        {place.title}
                      </Text>
                      <Text type="p" pt="0" pb="0">
                        {place.description}
                      </Text>
                    </Container>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}

            <Text type="h5" fontWeight="bold" textDecoration="underline">
              Weather and climate
            </Text>
            {weatherClimate.map((weather, i) => (
              <React.Fragment key={i.toString()}>
                <Text type="p" fontWeight="bold">
                  {weather.title}
                </Text>
                <Text type="p" pt="0">
                  {weather.description}
                </Text>
              </React.Fragment>
            ))}

            <Text type="h5" fontWeight="bold" textDecoration="underline">
              Inspiration Tags
            </Text>
            {inspirationTags.map((tag, i) => (
              <React.Fragment key={i.toString()}>
                <Text type="p" fontWeight="bold">
                  {tag}
                </Text>
              </React.Fragment>
            ))}
          </div>
          {width > 1024 && (
            <div className="sidebar sidebar-blog">
              <p className="title">Find cheap flights to any destination</p>
              <SearchForm baseIBEURL={baseIBEURL} updatePhone={() => {}} />
              <SubscribeForm smallSize />
              <div className="block">
                <TrustpilotBlock />
              </div>
            </div>
          )}
        </div>
        <VacationRecentArticles
          cards={props.data.vacationJson.cards}
          pageSlug={slug}
        />
        {width <= 1024 && (
          <div className="sidebar sidebar-blog">
            <p className="title">Find cheap flights to any destination</p>
            <SearchForm baseIBEURL={baseIBEURL} updatePhone={() => {}} />
            <SubscribeForm smallSize />
          </div>
        )}
      </div>
      <TrustpilotHorizontal />
    </Layout>
  );
};

export default VacationTemplate;

export const pageQuery = graphql`
  query($slug: String!, $name: String) {
    contentVacationJson(slug: { eq: $slug }) {
      slug
      title
      subTitle
      titleBackgroundImage
      overviewPlan
      overviewImages
      overview
      mustSee {
        description
        title
      }
      memoryMakers {
        title
        places {
          description
          image
          title
        }
      }
      weatherClimate {
        title
        description
      }
      inspirationTags
      days {
        description
        image
        text
      }
    }

    vacationJson(name: { eq: $name }) {
      cards {
        title
        backgroundImage
        slug
        tag
        backgroundColor
      }
    }
  }
`;
