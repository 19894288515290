import React from 'react';

import CardsVacation from '../VacationCategories/CardsVacation/CardsVacation';
import { getRandomElementsList } from '../../helpers/utils';

const COUNT_RANDOM_CARDS_ON_PAGE = 3;

const VacationRecentArticles = ({ cards, pageSlug }) => {
  const filteredCardsBySlug = cards.filter(card => card.slug !== pageSlug);

  return (
    <div className="wrapper-more">
      <h4>More Recent Articles</h4>
      <div className="wrapper-blog">
        <div className="wrapper-card">
          {getRandomElementsList(
            filteredCardsBySlug,
            COUNT_RANDOM_CARDS_ON_PAGE
          ).map(({ title, backgroundImage, backgroundColor, slug, tag }) => (
            <CardsVacation
              title={title}
              backgroundImage={backgroundImage}
              slug={slug}
              tag={tag}
              backgroundColor={backgroundColor}
              contentImage
              key={slug}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default VacationRecentArticles;
